



import axios from 'axios';
import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../helpers/config';



const API_URL = config.api.baseUrl;

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!username || !password) {
      alert('Please enter both username and password.');
      return;
    }

    setLoading(true);

    // Simulating authentication process


    try {

      const item = {
        id: "",
        username: username,
        password: password,
      };

      const response = await axios.post(
        `${API_URL}/?type=login`, item
      );

      console.log(response.data);
      // Assuming authentication is successful
      // You can replace this with actual API call to authenticate the user
      // For demonstration, let's assume the admin username is "admin" and password is "admin123"
      if (response.data.success) {
        


        sessionStorage.setItem("isLoggedIn", "true");



        navigate('/dashboard'); // Başarılı login olduğunda dashboard sayfasına yönlendirme
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className='mt-5'>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow border-0 border-top border-5 border-primary">
            <Card.Body>
              <div className="mb-4 mt-4 text-muted fst-italic">
                Please enter your username and password!
              </div>

              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="userName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Button
                  className="w-100"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} 
                  Login
                </Button>
                
               
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm; 

























/*  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    username: "ayfersenacar",
    password: "Ppayfersen123",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {  
    setLoading(true);

    try {

        const user = await login(values);
        const {token} = user;

        setLocalStorage("token", token);
        dispatch(loginSuccess(user))
        navigate("/dashboard")
        
    } catch (err) {
        const errMsg = err.response.data.message;
        swalAlert(errMsg, "error");
    }
    finally{
        setLoading(false);
    }


  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
 */