import React from "react";
import "./welcome.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaUserDoctor } from "react-icons/fa6";
import { BiSolidLike } from "react-icons/bi";
import { MdTaskAlt } from "react-icons/md";

const Welcome = () => {
  return (
    <div className="welcome">
      <Container>
        <Row className="g-5 align-items-center">
          <Col md={6}>
            <Image src="/images/hoca/altayhoca.jpg" className="img-fluid" />
          </Col>
          <Col md={6}>
            <h2>Profesyonel Estetik Dokunuşlar</h2>
            <p>
              Her zaman öncü ve yol gösterici olma görevini üstleniyoruz.
              Sektörde özellikle Estetik ve Plastik Cerrahi alanında. Hedefler,
              hizmet veren hastanemizin misyon ve vizyonu
              Estetik ve Plastik Cerrahide ileri teknikler ve en
              alanlarında başarılı doktorlar şöyle
            </p>
           <div className="d-flex">
           <FaUserDoctor style={{fontSize:"80px", marginRight:"15px"}} />
            <div>
            <h5> Hedefimiz</h5>
            <p>
              Tüm toplumumuzun sağlık eğitimine katkıda bulunmak,
              Müşterilerimizin memnuniyetini sağlayarak hatasız hizmet sunmak
              Hastalar en üst düzeyde
            </p>
            </div>
           </div>
           <div className="d-flex">
           <BiSolidLike style={{fontSize:"80px", marginRight:"15px"}}  />
           <div>
           <h5>Misyonumuz</h5>
            <p>
              Hastanemizi en çok tercih edilen sağlık kurumu haline getirmek
              Hastalarının memnuniyeti odaklı hizmetlerimizle,
              akrabalar ve çalışanlar.
            </p>
           </div>
           </div>
            <div className="d-flex">
            <MdTaskAlt style={{fontSize:"80px", marginRight:"15px"}} />
              <div>
              <h5>Vizyonumuz</h5>
            <p>
              Doğru tanıyı kullanarak önde gelen hastaneler arasında yer almak
              ve tedavi yöntemlerimizi yenilikçi, akılcı, ilkeli yaklaşımımızla
              ve sorumlu yaklaşım.
            </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Welcome;
