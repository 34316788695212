
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import Youtube from './youtube';
import axios from 'axios';
import { config } from "../../helpers/config";


const API_URL = config.api.baseUrl;

const Gallery = () => {

  const [youtubeLinkList, setyoutubeLinkList] = useState([]);
  useEffect(() => {
    getVideos();
  }, []);

  function getVideos() {
    axios.get(`${API_URL}/?type=videos`).then(function (response) {
      console.log(response.data);
      setyoutubeLinkList(response.data);
    });
  }


  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    getPhotos();
  }, []);

  function getPhotos() {
    axios.get(`${API_URL}/?type=photos`).then(function (response) {
      console.log(response.data);
      setPhotos(response.data);
    });
  }




  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <Container className='galeri'  style={{ overflowX: 'hidden' }}>
      <Row>
        <Col md={3} className='kategori'>
          <h2>Kategoriler</h2>
          <ul style={{ listStyleType: "none" }}>
            <li><Link to="/photos">Fotoğraflar</Link></li>
            <li><Link to="/videos">Videolar</Link></li>
          </ul>
        </Col>
        <Col md={9}>
          <h2 className='mb-3'>Fotoğraflar</h2>
          <Row md={4} className="photo w-100" style={{cursor:"pointer"}}>
  {photos.slice(0, 12).map((item) => (
    <Col key={item.id} md={4} className=' mb-4'>
      <Card onClick={() => handleImageClick(`/images/${item.image}`)}>
        <Card.Body>
          <Card.Img src={`/images/${item.image}`} />
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>
          <Row>
            <Col>
              <h2 className='videos-baslik'>Videolar</h2>
            </Col>
          </Row>
          <Row >
          {youtubeLinkList.map((item) => (
              <Col key={item.id} className='d-flex mt-2' xs={12} lg={6}>
                <Youtube youtubeVideoLink={item.url}/>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={!!selectedImage} onHide={handleCloseModal} centered>
  <Modal.Body>
    <img src={selectedImage} alt="Büyük Resim" style={{ maxWidth: "100%" }} />
    <Button variant="secondary" className="modal-close-button" onClick={handleCloseModal} style={{ position: "absolute", bottom: "10px", right: "10px" }}>
      Kapat
    </Button>
  </Modal.Body>
</Modal>
    </Container>
  );
};

export default Gallery;




